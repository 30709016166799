/*************************************
- Pop up notes
**************************************/

var popupNote = {};

popupNote.setBindings = function () {
    popupNote.icons.off("click.note").on("click.note", function () {
        var popupIcon = $(this).parent(),
            noteContainer = $(popupIcon.find("." + popupNote.containerClass));

        popupNote.setPosition(popupIcon, noteContainer);
        popupNote.toggleActivate(popupIcon, noteContainer);
        popupNote.setPosition(popupIcon, noteContainer);
    });
};

popupNote.toggleActivate = function (popupIcon, noteContainer) {
    if (noteContainer.hasClass(popupNote.hiddenClass)) {
        popupNote.targetArray = [popupIcon, noteContainer];

        popupNote.hideAll();
        noteContainer.removeClass(popupNote.hiddenClass);
        popupIcon.addClass(popupNote.iconActiveClass);

        popupNote.bindDocument();
    } else {
        popupNote.targetArray[1].addClass(popupNote.hiddenClass);
        popupNote.targetArray[0].removeClass(popupNote.iconActiveClass);
    }
};

popupNote.bindDocument = function () {
    document.addEventListener("mousedown", popupNote.handleClick);
};

popupNote.handleClick = function (e) {
    e.stopImmediatePropagation();
    var target = e.target;

    if ($(target).hasClass(popupNote.iconClass)) {
        document.removeEventListener("mousedown", popupNote.handleClick);
        return;
    }

    if (
        !$(target)
            .parents()
            .hasClass(popupNote.containerClass) &&
        !$(target).hasClass(popupNote.containerClass)
    ) {
        document.removeEventListener("mousedown", popupNote.handleClick);
        popupNote.toggleActivate(
            popupNote.targetArray[0],
            popupNote.targetArray[1]
        );
    }
};

popupNote.hideAll = function () {
    var containers = $("." + popupNote.containerClass);
    for (var i = 0; i < containers.length; i++) {
        $(containers[i])
            .parent()
            .removeClass(popupNote.iconActiveClass);
        $(containers[i]).addClass(popupNote.hiddenClass);
    }
};

popupNote.setPosition = function (note, noteContainer) {
    var rect = note[0].getBoundingClientRect(),
        halfContainer = noteContainer[0].scrollWidth / 2;

    // left side check
    if (rect.x < halfContainer) {
        noteContainer.css("left", -rect.x + 10 + "px");
    } else if (window.innerWidth - rect.x < halfContainer) {
        var leftOver = window.innerWidth - rect.x;
        var overAmount = halfContainer - leftOver;
        var position = overAmount + halfContainer;

        noteContainer.css("left", -position - 20 + "px");
    } else {
        noteContainer.css("left", -halfContainer + 10 + "px");
    }
};

popupNote.init = function () {
    popupNote.containerClass = "popup-note-container";
    popupNote.hiddenClass = "hidden";
    popupNote.iconActiveClass = "active";
    popupNote.iconClass = "popup-note-icon";
    popupNote.icons = $("." + popupNote.iconClass);
    popupNote.hideAll();
    popupNote.setBindings();
};


$(function () {
    popupNote.init();
});

